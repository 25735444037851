import React from 'react';
import { Row, Col, Button, Alert, Form } from 'react-bootstrap';

import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from '../../../hooks/useAuth';
import useScriptRef from '../../../hooks/useScriptRef';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import LdcApi from '../../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import { useState } from 'react';
import axios from 'axios';
import { serverConfig } from '../../../config/server-config';

const initialValues = {
  otp: '',
  pin: ''
};

const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .max(6)
    .min(6)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .optional(),

  pin: yup.string().optional('')
  // .matches(/^[0-9]+$/, 'Must be only digits')
  // .optional('PIN Code is required')
});

const appServerUrl = serverConfig.appServerUrl;
const OtpVerification = ({ className, ...rest }) => {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorText, setErrorText] = useState('');
  const { firebaseEmailPasswordSignIn, firebaseGoogleSignIn } = useAuth();

  // const googleHandler = async () => {
  //   try {
  //     await firebaseGoogleSignIn();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // https://api-ldc.goldenbuzz.in/api/Account/SendOTP?email=testadmin%40gmail.com&password=123456
  // https://api-ldc.goldenbuzz.in/api/Account/ValidateOTP?id=1&userType=D&otp=123
  // https://api-ldc.goldenbuzz.in/api/Account/ValidatePIN?id=8&userType=U&pin=1234
  //  https://api-ldc.goldenbuzz.in/api/Account/SignIn?Email=testadmin%40gmail.com&password=123456
  // https://api-ldc.goldenbuzz.in/api/Account/SignOut?code=eeee
  const users = JSON.parse(localStorage.getItem('user_data'));
  const ValiDateUser = JSON.parse(localStorage.getItem('user'));
  const otpVerySubmit = async (values) => {
    setIsSubmitting(true);
    const { pin, otp } = values;
    if (pin === '' && otp === '') {
      setErrorText('Please enter an OTP or PIN!');
    } else if (pin !== '' && otp !== '') {
      setErrorText('Please enter an OTP or PIN!');
    } else {
      // User/ValidatePIN?email=${users.email}&password=${users.password}&pin=${values.pin}
      if (otp === '') {
        setErrorText('');
        await LdcApi({
          method: 'GET',
          url: `Account/ValidatePIN?id=${ValiDateUser.id}&userType=${ValiDateUser.userType}&pin=${values.pin}`
        })
          .then((res) => {
            if (res.data.status.isSuccess === true) {
              // window.location.reload(true);
              setIsSubmitting(false);

              localStorage.setItem('_code', res?.data?.data?.securityCode);
              localStorage.setItem('user_info', JSON.stringify(res?.data?.data));
              localStorage.setItem('isLogin', true);
              // localStorage.removeItem('user_data');
              navigate('/diary');
              addToast('Logged in successfully.', { appearance: 'success' });
            } else {
              addToast('Invalid PIN', { appearance: 'error' });
            }
          })
          .catch((error) => {
            addToast('Invalid PIN', { appearance: 'error' });
          });
      } else {
        setErrorText('');
        await LdcApi({
          method: 'GET',
          url: `Account/ValidateOTP?id=${ValiDateUser.id}&userType=${ValiDateUser.userType}&otp=${values.otp}`
        })
          .then((res) => {
            if (res.data.status.isSuccess === true) {
              // window.location.reload(true);
              setIsSubmitting(false);
              navigate('/diary');
              localStorage.setItem('isLogin', true);

              localStorage.setItem('_code', res.data.data.securityCode);
              localStorage.setItem('user_info', JSON.stringify(res.data.data));
              // localStorage.setItem('isLogin', true);
              // localStorage.removeItem('user_data');

              addToast('Logged in Successfully`', { appearance: 'success' });
            } else {
              addToast('Invalid OTP', { appearance: 'error' });
            }
          })
          .catch((error) => {
            addToast('Invalid OTP', { appearance: 'error' });
          });
      }
    }

    // await LdcApi({
    //   method: 'GET',
    //   url: `User/ValidateOPT?username=${users.username}&password=${users.password}&otp=${values.otp}`
    // })
    //   .then((res) => {
    //     localStorage.setItem('_code', res.data.code);
    //     localStorage.setItem('isLogin', true);
    //     navigate('/diary');
    //     addToast('Logged in Successfully`', { appearance: 'success' });
    //   })
    //   .catch((error) => {
    //     addToast('Invalid OTP', { appearance: 'error' });
    //   });
  };

  const resendOtp = async () => {
    await LdcApi({
      method: 'GET',
      url: `Account/SendOTP?email=${users?.email}&password=${users?.password}`
    })
      .then((res) => {
        // alert(res.data);
        navigate('/otp');
      })
      .catch((error) => {
        addToast('Invalid username or password.`', { appearance: 'error' });
      });
  };
  return (
    <div>
      <p className="text-center text-danger">{errorText}</p>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={otpVerySubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} autocomplete="off">
            <Row className="my-4  ">
              <Col lg={12} sm={12}>
                <Row className="p-0 m-0">
                  <Col sm={5}>
                    <Form.Label className="mr-2">Enter SMS Code</Form.Label>
                  </Col>
                  <Col sm={7} className="p-0 m-0">
                    <Form.Control
                      name="otp"
                      type="password"
                      placeholder="OTP"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otp}
                      error={errors.otp}
                      autocomplete="new-password"
                    />
                    {touched.otp && errors.otp && <small className="text-danger error_field">{errors.otp}</small>}
                  </Col>
                </Row>
              </Col>
              <div className="d-flex mx-auto my-2 justify-content-center text-center">OR</div>
              <Col lg={12} sm={12}>
                <Row className="p-0 m-0">
                  <Col sm={5}>
                    <Form.Label className="mr-2">Management</Form.Label>
                  </Col>
                  <Col sm={7} className="p-0 m-0">
                    <Form.Control
                      name="pin"
                      type="password"
                      placeholder="Enter 6 digit PIN"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pin}
                      autocomplete="off"
                      error={errors.pin}
                    />
                    {/* {touched.pin && errors.pin && <small className="text-danger error_field">{errors.pin}</small>} */}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button
              className="btn-block"
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="primary"
              // onClick={() => navigate('/otp')}
            >
              {isSubmitting ? 'Loading...' : 'Verify'}
            </Button>
          </Form>
        )}
      </Formik>
      <p onClick={resendOtp} className="text-center mt-2 underline" style={{ cursor: 'pointer' }}>
        <ins> Resend OTP </ins>
      </p>
    </div>
  );
};

export default OtpVerification;

// axios.interceptors.request.use((config) => {
//   const token = localStorage.getItem('_code');
//   if (token) {
//     config.headers = {
//       SecurityCode: token
//     };
//   } else {
//     axios.defaults.headers.common.Authorization && delete axios.defaults.headers.common.Authorization;
//     config.headers = axios.defaults.headers;
//   }
//   return config;
// });
