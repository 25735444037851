import React, { useState, useRef, useEffect } from 'react';
import './CustomDropdown.css';
import LdcApi from '../../../LdcApis';
import { Modal } from 'react-bootstrap';
import LeadForm from '../../forms/LeadForm';
import image2 from './../../../assets/images/user/image 2.png';
import { live } from '../../../config/server-config';

const CustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showLead, setShowLead] = useState(false)
  const [allService, setAllService] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  useEffect(() => {
    LdcApi({
      method: 'GET',
      url: 'ServiceMaster/GetServiceMastesExcludedTest',
    })
      .then((res) => {
        //  if(response.data.status.isSuccess === true){
        setAllService(res?.data);
        // }
      })
      .catch((error) => {
        // callback({ status: error });
      });
  }, []);

  return (<>
    {/* <div className="dropdown1" ref={dropdownRef}>
      <div className="dropdown-toggle1" onClick={toggleDropdown}>
        <p style={{ margin: '0px', fontSize: '30px', position: 'absolute', bottom: '-5px' }}>+</p>
      </div>
      {isOpen && (<>
        <div className='container-triangle'></div>
        <div className="dropdown-menu1">
          <a className="dropdown-item1" onClick={() => {
            setShowLead(true);
          }}>
            <img src={image2} />
            <span>Add Lead</span>
          </a>
        </div>
      </>

      )}
    </div> */}
    <div className="dropdown-menu1">
      <a className="dropdown-item1" style={{ cursor: 'pointer' }} onClick={() => {
        setShowLead(true);
      }}>
        <img src={image2} />
        <span>Add Lead</span>
      </a>
    </div>

    <Modal show={showLead} centered size="lg" onHide={() => setShowLead(false)}
      style={{ paddingBottom: "20px" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          Add Lead
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", justifyContent: "center", padding: '0px' }}>
        <LeadForm
          fetchData={() => {
            // console.log("H")
          }}
          setShowLead={setShowLead}
          allService={allService}
          bool={true}
        />
      </Modal.Body>
    </Modal>
  </>
  );
};

export default CustomDropdown;
