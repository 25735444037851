const users = JSON.parse(localStorage.getItem('user_info'));
const userInfo = JSON.parse(localStorage.getItem('user_info'));
const viewOnly = userInfo?.roleName?.replace(/\s/g, '')?.toUpperCase() === 'VIEWONLY';
const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'appointmentBook',
          title: 'View Diary',
          type: 'item',
          url: '/diary',
          icon: 'feather icon-pie-chart',
          isClicked: false,
          isLogout: false,
          action: ''
        },
        users?.roleID == 1 || users?.roleID == 2 || users?.roleID == 3 || users?.roleID == 5 || users?.roleID == 11 || users?.roleID == 13
          ? {}
          : {
            id: 'provisionals',
            title: 'Provisionals',
            type: 'item',
            url: '/provisionals',
            icon: 'feather icon-bar-chart',
            isClicked: false,
            isLogout: false,
            action: ''
          },
        users?.roleID == 1 || users?.roleID == 2 || users?.roleID == 3 || users?.roleID == 5 ||  users?.roleID == 11 || users?.roleID == 13
          ? {}
          : {
            id: 'reviews',
            title: 'Review Tracker',
            type: 'item',
            url: '/reviews',
            icon: 'fa fa-star',
            isClicked: false,
            isLogout: false,
            action: ''
          },
        users?.roleID == 1 || users?.roleID == 2 || users?.roleID == 3 || users?.roleID == 5 || users?.roleID == 11 || users?.roleID == 13
          ? {}
          : {
            id: 'appointmentBook',
            title: 'Patients',
            type: 'item',
            url: '/patients',
            icon: 'feather icon-activity',
            isClicked: false,
            isLogout: false,
            action: ''
          }

        // {
        //   id: 'chg-password-modal',
        //   title: 'Log Out',
        //   type: 'item',
        //   icon: 'feather icon-lock',
        //   url: '/',
        //   isClicked: true,
        //   isLogout: false,
        //   action: 'PASSWORD_CHANGE'
        // }
      ]
    },
    {
      id: 'ui-element2',
      title: 'UI ELEMENT2',
      type: 'group',
      icon: 'feather icon-sidebar',

      children: [
        (users && users?.roleID === 1) ||
          users?.roleID === 2 ||
          users?.roleID === 3 ||
          users?.roleID === 5 ||
          users?.roleID === 10 ||
          users?.roleID == 11 ||
          users?.roleID == 13
          ? {}
          : { id: 'Compose', title: 'Compose SMS', type: 'item', url: '/compose-sms', icon: 'feather icon-command' },
        users?.roleID === 10
          ? {}
          : {
            id: 'taskist ',
            title: 'Task List ',
            type: 'item',
            url: '/tasks',
            icon: 'feather icon-file-text'
          },
        (users && users?.roleID === 7) ||
          (users && users?.roleID === 4) ||
          (users && users?.roleID === 1) ||
          (users && users?.roleID === 2) ||
          (users && users?.roleID === 3) ||
          (users && users?.roleID === 5) ||
          (users && users?.roleID === 10) ||
          (users && users?.roleID === 11) ||
          (users && users?.roleID === 13)
          ? {}
          : {
            id: 'Leadmanagement',
            title: 'Lead Management ',
            type: 'item',
            url: '/lead-management',
            icon: 'feather icon-briefcase'
          },
        {
          id: 'ConversionTracker',
          title: 'Conversion Tracker ',
          type: 'item',
          url: '/conversion-tracker',
          icon: 'feather icon-zap'
        },

        users && users?.roleID === 10
          ? {}
          : {
            id: 'LabTest',
            title: 'Lab Tests ',
            type: 'item',
            url: '/LabTest',
            icon: 'feather icon-thermometer'
          },
        (users && users?.roleID === 6) ||
          (users && users?.roleID === 1) ||
          (users && users?.roleID === 2) ||
          (users && users?.roleID === 3) ||
          (users && users?.roleID === 5) ||
          (users && users?.roleID === 10) ||
          (users && users?.roleID === 11) ||
          (users && users?.roleID === 13)
          ? {}
          : {
            id: 'EODBanking',
            title: 'EOD Banking ',
            type: 'item',
            url: '/EODBanking',
            icon: 'feather icon-credit-card'
          }
      ]
    },

    // {
    //   id: 'ui-element2',
    //   title: 'UI ELEMENT2',
    //   type: 'group',
    //   icon: 'feather icon-sidebar',
    //   children: [
    //     {
    //       id: 'templates',
    //       title: 'Templates',
    //       type: 'item',
    //       url: '/templates',
    //       icon: 'feather icon-pie-chart',
    //       isClicked: false,
    //       isLogout: false,
    //       action: ''
    //     }
    //   ]
    // },
    // users && users?.roleID !== 8
    //   ? {}
    (!viewOnly)?{
      id: 'ui-element2',
      title: 'UI ELEMENT2',
      type: 'group',
      icon: 'feather icon-sidebar',

      children: [
        {
          id: 'reports',
          title: 'Reports',
          type: 'collapse',
          isLogout: true,
          icon: 'feather icon-sidebar',
          children: [
            (users?.roleID == 8 || users?.roleID == 9) && (users?.roleName !== 'Manager')
              ? {
                id: 'MonthlyEODTotal',
                title: 'Monthly EOD Total',
                type: 'item',
                url: '/Monthly-EOD-Total',
                icon: 'feather icon-command'
              }
              : {},
            (users?.roleID == 8 || users?.roleID == 9) && (users?.roleName !== 'Manager')
              ? {
                id: 'DoctorHoursPage',
                title: 'Doctor Hours',
                type: 'item',
                url: '/Doctor-Hours',
                icon: 'feather icon-command'
              }
              : {},
            {
              id: 'Services',
              title: 'Services',
              type: 'item',
              url: '/reports',
              icon: 'feather icon-command'
            },
            {
              id: 'MarketingDataReport',
              title: 'Marketing Data Report',
              type: 'item',
              url: '/MarketingDataReport',
              icon: 'feather icon-command'
            },
            {
              id: 'conversionreport',
              title: 'Conversion Report',
              type: 'item',
              url: '',
              icon: 'feather icon-lock'
            },
            {
              id: 'investigations',
              title: 'Investigations',
              type: 'item',
              url: '',
              icon: 'feather icon-file-text'
            }
          ]
        }
      ]
    }:{},
    // delete & merge patient
    (users && users?.roleID === 8) || (users && users?.roleID === 9)
      ? {
        id: 'ui-element2',
        title: 'UI ELEMENT2',
        type: 'group',
        icon: 'feather icon-sidebar',
        children: [
          {
            id: 'master',
            title: 'Masters',
            type: 'collapse',
            isLogout: true,
            icon: 'feather icon-sidebar',
            children: [
              {
                id: 'delete_merge',
                title: 'Delete & Merge patient',
                type: 'item',
                url: '/delete-merge-patient',
                icon: 'feather icon-command'
              },
              {
                id: 'druglist',
                title: 'Drug List',
                type: 'item',
                url: '/drug-list',
                icon: 'feather icon-command'
              },
              {
                id: 'role',
                title: 'Role',
                type: 'item',
                url: '/role',
                icon: 'feather icon-command'
              },
              {
                id: 'room',
                title: 'Room',
                type: 'item',
                url: '/room',
                icon: 'feather icon-lock'
              },

              {
                id: 'Templates',
                title: 'SMS Templates',
                type: 'item',
                url: '/sms-templates',
                icon: 'feather icon-lock'
              },
              {
                id: 'service',
                title: 'Services',
                type: 'item',
                url: '/service',
                icon: 'feather icon-file-text'
              },
              {
                id: 'templates',
                title: 'Templates',
                type: 'item',
                url: '/templates',
                icon: 'feather icon-pie-chart',
                isClicked: false,
                isLogout: false,
                action: ''
              },
              {
                id: 'users',
                title: 'Other Users',
                type: 'item',
                url: '/users',
                icon: 'feather icon-server'
              },
              {
                id: 'doctor',
                title: 'Doctors',
                type: 'item',
                url: '/doctor',
                icon: 'feather icon-pie-chart'
              },
              
            
              (users?.roleID == 8)
              ? {
                id: 'ManagementPin',
                title: 'Management Pin',
                type: 'item',
                url: '/ManagementPin',
                icon: 'feather icon-pie-chart'
              }: {},
              {
                id: 'chamber',
                title: 'Rota',
                type: 'item',
                url: '/rota',
                icon: 'feather icon-pie-chart'
              }
            ]
          }
        ]
      }
      : {}
  ]
};

export default menuItems;
